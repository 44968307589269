@use '../../../App';

.list {
    list-style-type: none;
    
    background: var(--backgroundList);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(114, 114, 114, 0.3);

    position: absolute;
    width: 99.8%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1;
}

.inactive {
    opacity: 0;
}

.active {
    opacity: 1;
}

.items {
    margin-right: 20px;
    font-size: 20px;
    text-shadow: -3px 1px 13px rgba(0,0,0,1);
    cursor: pointer;
}

.items a {
    color: var(--color);
}

.items a:hover {
    text-shadow: 0px 1px 30px var(--color);
    font-weight: bold;
}

.menu {
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 35px;
    text-align: center;
}

.menu-icon {
    color: white;
    font-size: 30px;
}

.push-left {
    margin-right: auto;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .list {
        list-style-type: none;
        background: var(--backgroundList);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(10px);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 100;
        transition: all .40s ease;
        margin-top: 0px;
    }

    .inactive {
        display: none;
        opacity: 0;
        transition: all .40s ease;
    }

    .active {
        display: flex;
        opacity: 1;
        transition: all .40s ease;
    }

    .items {
        font-size: 35px;
        text-shadow: -3px 1px 13px rgba(0,0,0,1);
        color: white;
        cursor: pointer;
        margin-top: 50px;
    }

    .close {
        display: inline;
        position: fixed;
        z-index: 110;
    }
}
