@use '../../../App';

.listaProductos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 110px;
    margin-right: 110px;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .listaProductos {
        margin-left: 0px;
        margin-right: 0px;
        justify-content: space-evenly;
    }
}