@use '../../../App';

.input-container {
    height: 30px;
    position: relative;
    width: 46%;
    padding: 0 2% 0 2%;
}

.ic1 {
    margin-top: 20px;
}

.input {
    font-family: 'Georgia Pro Light', 'Georgia', 'Times New Roman', Times, serif;
    font-weight: normal;
    background: rgba(194, 194, 194, 0.1);
    border-radius: 12px;
    border-left: 1px solid var(--border-left);
    border-top: 1px solid var(--border-left);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 4px 4px 10px var(--box-shadow-form);
    box-sizing: border-box;
    color: var(--color);
    font-size: 15px;
    height: 100%;
    outline: 0;
    padding: 4px 15px 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
}

.input:hover {
    background: rgba(194, 194, 194, 0.2);
}

.placeholder {
    color: var(--color);
    left: 25px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 10px;
}

.input:focus~.placeholder,
.input:not(:placeholder-shown)~.placeholder {
    transform: translateY(-25px) translateX(-2px) scale(0.75);
}

.input:not(:placeholder-shown)~.placeholder {
    color: var(--color-placeholder);
}

.input:focus~.placeholder {
    font-weight: bold;
    color: var(--color);
}

.input_requerido {
    color: var(--color-required);
    font-size: 12px;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .input-container {
        width: 100%;
        padding: 0;
    }

    .placeholder {
        left: 15px;
    }    
}