@use '../../../App';

.contentDetailProductParent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: scroll;
    height: 88%;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #383838;
}

/* Chrome, Edge, and Safari */
.contentDetailProductParent::-webkit-scrollbar {
    width: 13px;
}

.contentDetailProductParent::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #383838;
}

.contentDetailProductParent::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
    border-radius: 5px;
    border: 2px solid #686868;
}

.contentDetailProductParent section {
    margin-left: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.imageSection-detailProduct {
    width: 55%;
}

.descriptionSection-detailProduct {
    width: 35%;
}

.longDescriptionSection-detailProduct {
    width: 100%;
}

.image-detailProduct {
    border-radius: 10px;
    text-align: center;
}

.imageProduct-detailProduct {
    max-width: 100%;
    max-height: 100%;
    border: 0.001em solid rgba(0, 0, 0);
    border-radius: 5px;
    box-shadow: rgba(255, 255, 255, 0.726) 10px 10px 1px 0px inset, rgba(255, 255, 255, 0.2) 0px 5px 20px 10px, rgba(255, 255, 255, 0.9) 0px 20px 20px -30px;
}

.description-detailProduct {
    width: 100%;
    font-size: 25px;
    text-align: justify;
}

.price-detailProduct {
    display: flex;
}

.addCart-detailProduct {
    bottom: 10px;
}

.addCart-detailProduct {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 10px 5px;
    text-align: center;
    text-transform: uppercase;
    transition: background-position 0.5s ease;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #ff522fDD 0%, #f09619DD 51%, #FF512FDD 100%);
    cursor: pointer;
}

.addCart-detailProduct:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

@media only screen and (max-width: App.$max-resolution-responsive) {

    /* Chrome, Edge, and Safari */
    .contentDetailProductParent::-webkit-scrollbar {
        width: 7px;
    }

    .imageSection-detailProduct {
        width: 100%;
    }

    .descriptionSection-detailProduct {
        width: 100%;
    }

    .addCart-detailProduct {}
}