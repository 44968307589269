.carrito {
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    padding-top: 8px;
    width: 35px;
}

.cartCounter {
    position: absolute;
    bottom: 5px;
    right: 30px;
    color: rgb(52, 185, 63);
    font-size: 30px;
    font-weight: bold;
    font-family: 'Georgia Pro', Georgia, 'Times New Roman', Times, serif;
    text-shadow: -3px 0px 13px rgb(255, 255, 255);
}
