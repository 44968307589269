.input-file-container {
    height: 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input-upload-file-hidden {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.input-upload-file {
    background: rgba(194, 194, 194, 0.1);
    border-radius: 12px;
    border-left: 1px solid var(--border-left);
    border-top: 1px solid var(--border-left);
    border-bottom: 2px solid var(--border-right);
    border-right: 2px solid var(--border-right);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 4px 4px 10px var(--box-shadow-form);
    box-sizing: border-box;
    color: var(--color);
    height: 100%;
    outline: 0;
    padding: 4px 10px 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
}

.input-upload-file:hover {
    background: rgba(194, 194, 194, 0.2);
}

.textPlaceholder {
    color: var(--color);
}

.pulse-container {
    width: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    padding-top: 14px;
}

.pulse-bubble {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #919191;
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}

.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}

.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}

@keyframes pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: .25;
        transform: scale(.75);
    }
}

.percentNumber {
    display: block;
    text-align: end;
}

.fadeOut {
    color: var(--color-green);
    opacity: 0;
    animation: fadeOut 2s;
    -webkit-animation: fadeOut 2s;
    -moz-animation: fadeOut 2s;
    -o-animation: fadeOut 2s;
    -ms-animation: fadeOut 2s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.button-upload {
    width: 135px;
    height: 25px;
    margin-top: 3px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
    background: rgb(24, 53, 77);
}

.button-upload,
.button-upload span {
    transition: 200ms;
}

.button-upload .text {
    transform: translateX(10px);
    color: white;
    font-weight: bold;
}

.button-upload .icon {
    position: absolute;
    border-left: 1px solid #757575;
    transform: translateX(58px);
    height: 25px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-upload svg {
    width: 15px;
    fill: #eee;
}

.button-upload:hover {
    background: rgb(53, 76, 95);
}

.button-upload:hover .text {
    color: transparent;
}

.button-upload:hover .icon {
    width: 85px;
    border-left: none;
    transform: translateX(0);
}

.button-upload:focus {
    outline: none;
}

.button-upload:active .icon svg {
    transform: scale(0.8);
}

