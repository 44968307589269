@use '../../App';

.panelAcercaDe {
    margin: 0 15% 0 15%;
    font-family: 'Georgia Pro', 'Georgia', 'Times New Roman', Times, serif;
    font-size: 25px;
    position: relative;
}

.panelAcercaDe p {
    text-align: justify;
    margin-bottom: 40px;
    z-index: 2;
}

.minis img {
    padding: 0px 0px 10px 10px;
}

/* .logoCentral {
    position: absolute;
    background: url("../../../img/Logo.png") no-repeat;
    background-size: 100% auto;
    width: 50%;
    height: 100%;

    margin: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}
 */
.ramaLateral {
    position: absolute;
    background: url("../../img/RamaLateralDer.png") no-repeat;
    background-size: 250px auto;
    top: 150%;
    right: 0px;
    width: 250px;
    height: 525px;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .panelAcercaDe {
        margin: 0 12% 0 12%;
        font-size: 1.3rem;
    }
    
    .ramaLateral {
        background-size: 150px auto;
        top: 80%;
        width: 150px;
    }
    
}