@use '../../../App';

.glassBackground {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 95%;
  height: 90%;
  padding: 10px 2px 10px 10px;
  background-color: rgba(0, 0, 0, 0.347);
  border-radius: 10px 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: var(--box-shadow-product) 0px 10px 36px 0px, var(--box-shadow-product) 0px 0px 0px 1px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 2;
  opacity: 0;
  top: -200%;
  transform: translate(-50%, -50%) scale(0.5);
  transition: opacity 300ms ease-in-out,
    top 1000ms ease-in-out,
    transform 1000ms ease-in-out;
}

.glassBackground.active {
  opacity: 1;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 300ms cubic-bezier(0.18, 0.89, 0.43, 1.19);
}

hr {
  border: 0;
  margin: 0.5em auto 0.5em auto;
  max-width: 100%;
  background-position: 50%;
  box-sizing: border-box;
}

.shine {
  height: 20px;
  width: 100%;
  background-image: radial-gradient(farthest-side at 47% -80%,
      hsla(0, 0%, 100%, 0.3),
      hsla(0, 0%, 0%, 0));
  position: relative;
}

.shine::before {
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  background-image: linear-gradient(90deg,
      hsla(0, 0%, 0%, 0),
      hsla(0, 0%, 0%, 0.75) 50%,
      hsla(0, 0%, 0%, 0));
}

@media only screen and (max-width: App.$max-resolution-responsive) {
  .glassBackground {
    width: 89%;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 20px;

  }

}