.loading {
    position: fixed;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px;
    width: auto;
    min-width: 300px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
