@use '../../../App';

.titulo {
    font-size: 30px;
    text-align: center;
    height: 60px;
    color: #d46f37;
}

.gridLogo {
    justify-content: space-evenly;
    position: relative;
    top: -50px;
}

.logo {
    position: relative;
    text-align: center;
    background: url("../../../img/MarcoPlantasCuadradoLogo.png") no-repeat top;
    background-size: auto 450px;
    width: 100%;
    height: 430px;
    z-index: 0;
}

.troncoDivisor {
    position: relative;
    text-align: center;
    background: url("../../../img/TroncoHorizontal.png") repeat-x top;
    background-size: auto 50px;
    width: 100%;
    height: 50px;
    z-index: 0;
}

.kokedama {
    position: absolute;
    background: url("../../../img/kokedadaColgante2.png") no-repeat;
    background-size: 100px auto;
    top: 430px;
    left: 10px;
    width: 100px;
    height: 330px;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .logo {
        background-size: auto 200px;
        width: 100%;
        height: 190px;
        z-index: 0;
    }
    
    .kokedama {
        top: 190px;
    }
    
    
}