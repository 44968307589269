@use '../../App';

footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to top, #b46c00 0%, #917f45 100%);
    background-image: url("../../img/footer.png");
    background-position: left bottom;
    background-size: auto 100%;
    color: white;
}

.footer-container {
    color: var(--color);
    display: flex;
}

.footer-container {
    margin: 20px;
    font-family: 'Georgia Pro', Georgia, 'Times New Roman', Times, serif;
}

.footer-container div h2 {
    margin-bottom: 10px;
}

.social-section {
    flex-grow: 1;
    flex-basis: 100%;
}

.social-section li {
    list-style-type: none;
}

.social-section li a {
    color: var(--color);
    text-decoration: none;
    vertical-align: super;
}

.social-section li a:hover {
    color: cornflowerblue;
    text-decoration: underline;
}

.about-section {
    flex-grow: 1;
    flex-basis: 100%;
}

.about-section a {
    color: var(--color);
}

.contact-section {
    flex-grow: 1;
    flex-basis: 100%;
}

.contact-section h2 {
    text-align: end;
}

.contactInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px;
}

.infoRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 0px;
    text-align: end;
}

.info {
    width: 250px;
}

.contact-section a {
    color: var(--color);
    text-decoration: none;
}

.contact-section a:hover {
    color: cornflowerblue;
    text-decoration: underline;
}

.copyright {
    padding: 15px;
    text-align: center;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .footer-container {
        flex-direction: column;
    }

    .social-section {
        margin-bottom: 20px;
    }
    
    .about-section {
        margin-bottom: 20px;
    }
    
    .contact-section h2 {
        text-align: start;
    }

    .infoRow {
        justify-content: space-around;
    }
}