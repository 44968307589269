@use '../../../App';

.searchbar {
    max-width: 600px;
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
}
  
  .fadeInWidth {
    animation: 0.2s linear fadeInWidth;
  }
  
  .fadeOutWidth {
    animation: 0.2s linear fadeOutWidth;
  }
  
  /* .fadeIn {
    animation: 0.8s linear fadeIn;
  }
  .fadeOut {
    animation: 1s linear fadeOut;
  } */
  
  .search-input {
    color: white;
    font-size: 20px;
    border: 0;
    outline: 0;
    background: none;
    padding: 0px 15px;
    transition: width 0.4s linear;
    caret-color: rgb(0, 255, 234);
    animation-name: fadeIn;
    animation-duration: 0.5s;
    
  }

  .search-input::placeholder {
    color: white;
  }
  
  .search-icon {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 25px;
  }
  
  .icon-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 41px;
    border-radius: 20px;
  }
  .icon-bg:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  @media only screen and (max-width: App.$max-resolution-responsive) {
    .searchbar {
      max-width: 210px;
  }
  }
  
  @keyframes fadeInWidth {
    0% {
      width: 30px;
    }
    100% {
      width: 200px;
    }
  }
  @keyframes fadeOutWidth {
    0% {
      width: 200px;
    }
    100% {
      width: 30px;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  