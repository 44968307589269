@use '../../../App';

.containerForm {
    display: flex;
    justify-content: center;
}

.productForm {
    background: var(--backgroundForm);
    box-shadow: 4px 4px 30px var(--box-shadow-form);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-left: 1px solid var(--form-border-left);
    border-top: 1px solid var(--form-border-left);
    border-right: 1px solid var(--form-border-right);
    border-bottom: 1px solid var(--form-border-right);
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px;
    width: 650px;
}

.divInputsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.divColumn {
    width: 40%;
    padding: 0 10px 0 10px;
}

.buttonFormContent {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .productForm {
        width: 320px;
    }

    .divInputsContainer {
        flex-direction: column;
    }

    .divColumn {
        width: 100%;
        padding: 0;
    }
    
}