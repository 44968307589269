@use '../../App';

.carousel .slider-wrapper {
    width: 80%;
}

.carouselItem {
    height: 400px;
}

.carouselItem img {
    height: 100%;
    width: auto!important;
}

.panelHome {
    margin: 0 15% 0 15%;
    font-family: 'Georgia Pro', 'Georgia', 'Times New Roman', Times, serif;
    font-size: 25px;
    position: relative;
}

.panelHome p {
    text-align: justify;
    margin-bottom: 40px;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center h2 {
    text-align: center;
}

.center a {
    text-align: center;
}

.tiendaImg {
    width: 500px;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .panelHome {
        margin: 0 12% 0 12%;
        font-size: 1.3rem;
    }
    
    .tiendaImg {
        width: 250px;
    }

    .center h2 {
        font-size: 1.5rem;
    }
    
}