@use '../../../App';

.container {
    display: flex;
    margin: 0 3rem 0 3rem;
    flex-wrap: wrap;
    position: relative;
}

@media only screen and (max-width: App.$max-resolution-responsive) {
    .container {
        display: flex;
        margin: 0 0rem 0 0rem;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        overflow: auto;
    }
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    box-shadow: 0 0 20px var(--box-shadow-form);
    text-shadow: 2px 2px 8px var(--color);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.styled-table thead tr {
    background-color: var(--row-even-color);
    color: var(--color);
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 5px 10px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
    text-shadow: 2px 2px 8px var(--colorForm);
    background-color: var(--row-color);
    color: var(--color);
}

.styled-table tbody tr:hover {
    background-color: rgb(112, 129, 150);
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: var(--row-even-color);
    text-shadow: 2px 2px 8px var(--colorForm);
}

.styled-table tbody tr:nth-of-type(even):hover {
    background-color: rgb(112, 129, 150);
}

.styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #80808081;
}

.lastColumn {
    display: flex;
    flex-direction: row-reverse;
}
