
.checkbox {
	font-family: 'Georgia Pro Light', 'Georgia', 'Times New Roman', Times, serif;
    font-weight: normal;
    background: rgba(194, 194, 194, 0.1);
    border-radius: 12px;
    border-left: 1px solid var(--border-left);
    border-top: 1px solid var(--border-left);
    border-bottom: 2px solid grey;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 4px 4px 10px var(--box-shadow-form);
    box-sizing: border-box;
    color: var(--color);
    font-size: 15px;
    height: 100%;
	width: 100%;
    outline: 0;
    padding: 4px 15px 0;

}

.checkbox:checked + label {
	color: rgb(67, 173, 63);
}